import {
  isSameDay,
  isSameYear,
} from 'core/libs/date-fns';

import { dateFormat } from 'core/utils/dates';

/**
 * Функция определяет какие топики имеют исключающий тип, по умполчанию это `news`.
 * Полученный объект исползуется в пропсе `cardsReplacement` компонента `Feed`.
 */
export function cardReplacements(
  topics = [],
  Card,
  condition = topic => topic.attributes.topic_type === 'news'
) {
  return topics.reduce((acc, item, index) => {
    if (condition(item)) {
      acc[index] = Card;
    }
    return acc;
  }, {});
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

/**
 * UCMS-2728 Делаем проверку на то что пост был опубликован до переезда на LB.
 */
export function isLegacyPost(publishedAt) {
  const releaseDate = '2018-08-15';

  return new Date(releaseDate).getTime() > new Date(publishedAt).getTime();
}


/**
 * Проверяет существование переобложки.
 * @param {number} cover.width - ширина переобложки.
 * @param {number} cover.height - высота переобложки.
 * @param {string} cover.rel_url - относительный URL переобложки.
 */

export function imageVersionExists(cover) {
  if (cover && cover.width && cover.height && cover.rel_url) return true;
  return false;
}

export function doubleDashesReplacer(str, replace) {
  if (!str) return null;
  return str.replace(/-{2}/g, replace || '');
}

export function textReplacer(string, regexes) {
  if (!string) return null;

  return regexes.reduce((newString, [regex, replace]) => (
    newString.replace(regex, replace)
  ), string);
}


/**
 * Преобразует дату в для отображения в топиках.
 *
 * @param {string|number} date - дата в формате, поддерживаемом конструктором new Date().
 * @returns {string}
 */
export function topicDateFormat(rawDate) {
  if (!rawDate) return '';

  const now = new Date();
  const date = new Date(rawDate);

  if (isSameDay(now, date)) return dateFormat(date, 'H:mm');
  if (isSameYear(now, date)) return dateFormat(date, 'd MMMM, H:mm');
  return dateFormat(date, 'd MMMM y, H:mm');
}
