import PropTypes from 'prop-types';
import { nest } from 'core/libs/recompose';
import deepmerge from 'core/libs/deepmerge';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withTheme from 'core/components/theme';

import TopicContent from 'core/components/TopicContent';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { Indent } from 'core/components/Wrappers';
import Erid from 'core/components/Erid';

import resolveRelationships from 'core/utils/relationships';

import { isLegacyPost, textReplacer } from 'site/utils';

import {
  VerticalIndent,
  NegativeMobileSideIndent,
} from 'site/components/Indents';

import Authors from 'site/components/Authors';
import Sponsored from 'site/components/Ads/Sponsored';

// eslint-disable-next-line import/no-namespace
import * as Vikonts from 'site/vikonts';

import {
  InRead,
  Native3,
  AdCenter,
} from 'site/components/Ads/desktop';

import {
  InPage,
  Content1,
  Content2,
  Content3,
  Content4,
  ContentSpec,
} from 'site/components/Ads/mobile';

import {
  REG_LOGO,
  REG_DOUBLE_DASHES,
  REG_TRIPLE_DASHES_NO_BREAK,
} from 'site/constants';

import './index.styl';

const requiredPayloadImports = [
  'content',
  'authors',
  'tags',
  'social_network',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  content: {},
  authors: [],
  tags: [],
  social_network: {},
});

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

const desktopAds = [
  {
    before: 200,
    after: 200,
    showOnlyOnce: true,
    renderer: AdCenter,
  },
  {
    before: 1200,
    after: 400,
    showOnlyOnce: true,
    renderer: InRead,
  },
  {
    before: 600,
    after: 400,
    renderer: Native3,
  },
  {
    before: 1200,
    after: 400,
    renderer: AdCenter,
  },
];

const mobileAds = [
  Content1,
  InPage,
  ContentSpec,
  Content2,
  Content3,
  Content4,
].map((renderer, index, ads) => ({
  before: 650,
  after: 400,
  showOnlyOnce: ads.length !== index + 1,
  renderer: nest(NegativeMobileSideIndent, renderer),
}));

const blocks = [
  'snippet',
  'summary',
  'galleryLink',
  'image',
  'photoGallery',
  'readMore',
  'slides',
  'markdown',
].reduce((items, renderer) => {
  const VikontComponent = Vikonts[capitalize(renderer)];
  items[renderer] = (block, parentProps, ctx) => (
    <VikontComponent {...{ block, parentProps, ctx }} />
  );
  return items;
}, {});

/**
 * Компонент актуален для страниц: article, news.
 */
function SiteTopicContent({ content, theme, isDesktop }) {
  const {
    attributes: {
      published_at: publishedAt,
      erid,
    },
  } = content;

  const {
    content: {
      widgets,
    },
    authors,
  } = relationships(content);

  if (!widgets) return null;

  const isLegacy = isLegacyPost(publishedAt);

  const modifiedWidgets = widgets.map(widget => {
    if (widget.type !== 'markdown') return widget;

    const { body } = widget.attributes;
    return deepmerge(
      widget,
      {
        attributes: {
          body: textReplacer(body, [
            [REG_LOGO, '$1<span class="topic-end"/>'],
            [REG_DOUBLE_DASHES, '$1&ndash;$2'],
            [REG_TRIPLE_DASHES_NO_BREAK, '$1&mdash;$2'],
          ]),
        },
      }
    );
  });

  return (
    <Indent top={isDesktop ? 36 : 24}>
      <Sponsored />

      <Authors authors={authors} isLink />
      <VerticalIndent indent={15} />

      {/* UCMS-2778 Выделяем первый абзац текста как анонс, если топик опубликован до переезда на LB */}
      <Indent bottom={15}>
        <style jsx>{`
          :global(.vikont:first-child .text)
            :global(p:first-child)
              font ${isLegacy ? theme.texts.lead.font : theme.texts.body.font}
              color ${isLegacy ? theme.texts.lead.color : theme.texts.body.color}
        `}</style>
        <TopicContent
          content={modifiedWidgets}
          {...{
            blocks,
            mobileAds,
            desktopAds,
          }}
        />
        {erid && <Erid erid={erid} maxWidth='100%' />}
      </Indent>
      <AdWrapper bottom={15}>
        <Sponsored />
      </AdWrapper>
    </Indent>
  );
}

SiteTopicContent.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(withTheme(SiteTopicContent));
