import color from 'core/libs/color';
import deepmerge from 'core/libs/deepmerge';

import bindPropsHOC from 'core/components/bindProps';

import Logo from 'site/components/Logo';
import Burger from 'site/icons/Burger.svg';
import Close from 'site/icons/Close.svg';
import Comments from 'site/icons/Comments.svg';
import GalleryArrowRight from 'site/icons/GalleryArrowRight.svg';
import GalleryArrowLeft from 'site/icons/GalleryArrowLeft.svg';
import SubscribeLogo from 'site/icons/SubscribeLogo.svg';
import PremiumStory from 'site/icons/PremiumStory.svg';
import Video from 'site/icons/Video.svg';
import Photo from 'site/icons/Photo.svg';

import { Card2Type0Xs } from 'site/cards/Card2';
import CompareCard from 'site/cards/CompareCard';

import { MOBILE, DESKTOP } from 'core/components/breakpoint/values';

import {
  BLOCK_VERTICAL,
  BLOCK_HORIZONTAL,
  TopicContentIndent,
} from 'site/components/Indents';

import notFoundImg from 'site/pages/images/404.jpg';
import jsError from 'site/pages/images/error.jpg';

import fonts from './fonts';


const sizes = {
  extraSmall: {
    font: `400 12px/14px ${fonts.display}`,
    width: '140px',
    height: '30px',
    padding: '6px 12px',
  },
  small: {
    font: `700 12px/14px ${fonts.display}`,
    width: '60px',
    height: '30px',
    padding: '8px 12px',
  },
  medium: {
    font: `700 14px/16px ${fonts.display}`,
    width: '100px',
    height: '40px',
    padding: '10px 20px',
  },
  large: {
    font: `700 16px/16px ${fonts.display}`,
    width: '180px',
    height: '52px',
    padding: '10px 30px',
  },
  extraLarge: {
    font: `bold 14px/20px ${fonts.display}`,
    width: '300px',
    height: '42px',
    padding: '12px 70px',
  },
};

export const FooterLogo = bindPropsHOC({ fill: '#fff' })(Logo);

const icons = {
  arrowRight: GalleryArrowRight,
  arrowLeft: GalleryArrowLeft,
  logo: Logo,
  close: Close,
  burger: Burger,
  comments: Comments,
  Video,
  Photo,
};

const layout = {
  sideColumnIndent: '24px',
  contentColumnMaxWidth: '908px',
  indents: {
    top: BLOCK_VERTICAL,
    right: BLOCK_HORIZONTAL,
    bottom: BLOCK_VERTICAL,
    left: BLOCK_HORIZONTAL,
  },
};

const errorPage = {
  imgUrl: jsError,
  background: '#fff',
  imgWidth: 390,
  title: {
    font: `56px/56px ${fonts.display}`,
  },
  subtitle: {
    font: `30px/40px ${fonts.text}`,
  },
  card: Card2Type0Xs,
  padding: '80px 0 0',
  popular: {
    background: `linear-gradient(0deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(245, 245, 245, 0.4) 99.13%,
      rgba(0, 0, 0, 0.4) 105.94%), #f2f2f2`,
    padding: '0 20px 20px',
  },
};

export default function baseTheme(atoms) {
  const texts = {
    lead: {
      font: `300 24px/34px ${fonts.text}`,
      letterSpacing: 0,
      color: atoms.colors.primary,
      marginTop: '15px',
    },

    body: {
      font: `300 20px/32px ${fonts.text}`,
      color: atoms.colors.primary,
    },

    h1: {
      font: `700 42px/1.2 ${fonts.display}`,
      color: atoms.colors.primary,
    },

    h2: {
      font: `700 32px/36px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    h3: {
      font: `700 24px/28px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    h4: {
      font: `700 18px/24px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    h5: {
      font: `700 16px/18px ${fonts.text}`,
      color: atoms.colors.primary,
    },

    caption1: {
      font: `300 14px/18px ${fonts.text}`,
      color: atoms.colors.primary,
    },

    quote: {
      font: `700 20px/30px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    topicInfo: { // Info, Date, Rubric
      font: `700 14px/16px ${fonts.display}`,
      letterSpacing: '-0.1px',
      wordSpacing: '1px',
      color: atoms.colors.hint,
    },

    author: {
      font: `300 16px/18px ${fonts.text}`,
      color: atoms.colors.hint,
    },

    get tableCell() {
      return this.author;
    },

    authorSmall: {
      font: `300 14px/18px ${fonts.text}`,
      color: atoms.colors.hint,
    },

    imageDescription: {
      font: `300 14px/18px ${fonts.text}`,
      color: atoms.colors.placeholder,
    },

    imageCopyright: {
      font: `300 16px/18px ${fonts.text}`,
      color: atoms.colors.placeholder,
    },

    coverCopyright: {
      font: `300 14px/18px ${fonts.text}`,
      color: atoms.colors.hint,
    },

    get display1() {
      return this.h1;
    },
    get display2() {
      return this.h2;
    },
    get display3() {
      return this.h3;
    },
    get display4() {
      return this.h4;
    },

    /**
     * Дальше идут фоллбеки для обратной совместимости
     * со старым набором стилей шрифтов.
     *
     * TODO: удалить их после обновления атомов в Layout Builder.
     */
    get headline() {
      return this.h1;
    },
    get title1() {
      return this.h1;
    },
    get title2() {
      return this.h2;
    },
    get title3() {
      return this.h3;
    },
    get title4() {
      return this.h4;
    },
    get title5() {
      return this.h5;
    },
    get caption2() {
      return this.imageDescription;
    },
    get caption3() {
      return this.imageCopyright;
    },
    get caption4() {
      return this.author;
    },
  };

  const shapkaLink = {
    padding: '0 22px',
    font: `700 11px/1 ${fonts.text}`,
    letterSpacing: '1px',
    textTransform: 'uppercase',

    hover: {
      color: atoms.colors.primary,
      decoration: 'underline',
    },

    current: {
      color: atoms.colors.primary,
      decoration: 'underline',
    },
  };

  const shapka = {
    link: shapkaLink,
    dropdown: {
      more: shapkaLink,
      link: {
        ...shapkaLink,
        padding: '10px 15px',
      },
      premiumTopic: {
        link: {
          ...shapkaLink,
          padding: '6px 20px 6px 0',
        },
      },
    },
    background: atoms.colors.layout,
    stickyBackground: atoms.colors.layout,
    backgroundOpened: atoms.colors.layout,
    logo: {
      width: 200,
      height: 48,
    },
    padding: '0 22px',

    labelNoAds: {
      fontWeight: 'bold',
      color: '#000',
      textTransform: 'none',
    },
  };

  const scooter = {
    social: {
      margin: '0 0 0 40px',
      size: 30,
      iconSize: 14,
      borderRadius: 30,
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.active2,
        border: '1px solid transparent',
      },
      hover: {
        color: atoms.colors.active2,
        background: 'transparent',
        border: `1px solid ${atoms.colors.active2}`,
      },
    },
    servicesItem: {
      marginTop: 0,
    },
    padding: '0 22px',
    logo: {
      Icon: FooterLogo,
      width: '170px',
      height: '40px',
    },
    menuLink: {
      font: `700 11px/1 ${fonts.text}`,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      idle: {
        color: atoms.colors.hint,
        decoration: 'none',
      },
      hover: {
        color: atoms.colors.hint,
        decoration: 'underline',
      },
      active: {
        color: atoms.colors.hint,
        decoration: 'none',
      },
    },
  };

  const topicFooter = {
    commentButton: {
      type: 'primary',
      font: `700 16px/20px ${fonts.text}`,
      topSpacing: BLOCK_VERTICAL,
    },
    links: {
      showComma: false,
      borderBottom: `2px ${atoms.colors.active1} solid`,
      font: `700 14px/17px ${fonts.display}`,
      marginLeft: '15px',
      title: {
        font: `300 14px/20px ${fonts.text}`,
        color: atoms.colors.primary,
      },
      hover: {
        borderBottom: '2px transparent solid',
      },
    },
    socialMedia: {
      type: 'fancy',
    },
    block: {
      spacing: BLOCK_VERTICAL,
    },
  };

  const topicTags = {
    font: `700 14px/17px ${fonts.display}`,
  };

  const link = {
    article: {
      idle: {
        color: atoms.colors.primary,
        decoration: 'underline',
      },
      hover: {
        color: atoms.colors.primary,
        decoration: 'none',
      },
      active: {
        color: atoms.colors.primary,
        decoration: 'none',
      },

      visited: {
        color: atoms.colors.primary,
        decoration: 'underline',
      },
    },
  };

  const button = {
    const: {
      sizes,
      radius: '4px',
    },
    tertiary: {
      idle: {
        color: atoms.colors.primary,
        background: atoms.colors.active1,
      },
      hover: {
        color: atoms.colors.primary,
        background: color(atoms.colors.active1).alpha(0.9).string(),
      },
    },
  };

  const breadcrumbs = {
    const: {
      textTransform: 'uppercase',
      spaceBetween: 24,
    },
    desktop: {
      item: {
        font: `14px/14px ${fonts.display}`,
      },
    },
    mobile: {
      item: {
        font: `12px/14px ${fonts.display}`,
      },
    },
  };

  const gallery = {
    slide: {
      background: color(atoms.colors.primary).alpha(0.08).string(),
    },

    arrows: {
      idle: {
        fill: color('#FFF').alpha(0.5).string(),
        stroke: '#000',
      },
      idleFullscreen: {
        fill: color('#FFF').alpha(0.5).string(),
        stroke: '#000',
      },
      hover: {
        fill: '#FFF',
        stroke: '#000',
      },
      hoverFullscreen: {
        fill: '#FFF',
        stroke: '#000',
      },
    },
  };

  const topicPages = {
    arrow: {
      idle: {
        color: atoms.colors.active1,
      },

      hover: {
        fill: atoms.colors.active1,
      },
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 24px/28px ${fonts.display}`,
      },
      info: {
        font: `700 14px/17px ${fonts.text}`,
      },
      link: {
        borderBottom: `2px ${atoms.colors.active1} solid`,
        hover: {
          color: atoms.colors.active1,
          borderBottom: '2px transparent solid',
        },
      },
    },
  };

  const input = {
    const: {
      sizes: {
        extraSmall: {
          ...sizes.extraSmall,
        },
        small: {
          ...sizes.small,
          font: `12px/14px ${fonts.text}`,
        },
        medium: {
          ...sizes.medium,
          font: `14px/16px ${fonts.text}`,
        },
        large: {
          ...sizes.large,
          font: `16px/16px ${fonts.text}`,
        },
      },
      radius: '3px',
    },
    basic: {
      idle: {
        background: 'transparent',
        border: `2px solid ${atoms.colors.placeholder}`,
      },

      hover: {
        background: 'transparent',
        border: `2px solid ${atoms.colors.placeholder}`,
      },

      focused: {
        background: 'transparent',
        border: `2px solid ${atoms.colors.placeholder}`,
      },

      disabled: {
        background: atoms.colors.hint,
        border: `2px solid ${atoms.colors.placeholder}`,
      },

      error: {
        background: 'transparent',
        border: `2px solid ${atoms.colors.error}`,
      },
    },
  };

  const bouesque = {
    const: {
      size: 'medium',
    },
  };

  const bullet = {
    const: {
      size: '10px',
    },
  };

  const comments = {
    const: {
      CountIcon: icons.comments,
    },
  };

  const mark = {
    padding: '0 0.05em',
    background: '#FFFBD1',
    color: atoms.colors.dark,
  };

  const topicContent = {
    widgetsWithMaxWidth: null,
    widgetsWithNegativeMobileIndent: {
      'smartTeaser': true,
    },
    heading: {
      clear: 'none',
    },
    showErid: false,
  };

  const spammer = {
    Icon: SubscribeLogo,
    [MOBILE]: {
      formElements: {
        radius: '4px',
      },
    },
    [DESKTOP]: {
      borderRadius: '4px',
      formElements: {
        radius: '4px',
      },
    },
  };

  const drum = {
    arrow: {
      Left: GalleryArrowLeft,
      Right: GalleryArrowRight,
      idle: {
        color: color(atoms.colors.active1).alpha(0.6).string(),
      },
      hover: {
        color: (
          color(atoms.colors.active1).string()
        ),
      },
    },
  };

  const vikontImage = {
    maxWidth: 908,
    maxHeight: 1100,
  };

  const vikontReadMore = {
    block: {
      background: atoms.colors.content,
      padding: '20px 30px 30px',
    },
    title: {
      color: atoms.colors.cardReadMore,
      font: `14px/17px ${fonts.text}`,
      marginBottom: '15px',
    },
    drum: {
      itemWidth: 312,
      spaceBetween: 20,
    },
    feed: {
      interitemSpacing: 15,
    },
  };

  const vikontIncut = {
    borderWidth: '0 0 0 10px',
    borderColor: atoms.colors.active1,
    padding: '0 0 0 15px',
    font: texts.quote.font,
    color: texts.quote.color,
    textAlign: 'left',
  };

  const commentsPreview = {
    headline: {
      font: texts.h2.font,
      color: texts.h2.color,
    },
    name: {
      font: `bold 16px/20px ${fonts.text}`,
    },
    date: {
      font: `normal 12px/16px ${fonts.text}`,
    },
    comment: {
      font: `normal 16px/20px ${fonts.text}`,
    },
  };

  const author = {
    types: {
      expert: {
        background: atoms.colors.active1,
        color: atoms.colors.primary,
        borderRadius: '10px',
        textTransform: 'none',
        border: 'none',
      },
    },
  };

  const personal = {
    circleCheckMark: {
      stroke: '#000',
    },
    profileAvatar: {
      isCircular: true,
    },
  };

  const fontSizeChangerButtons = {
    filter: 'none',
  };

  const topicHeaderTexts = {
    title: { ...texts.h1 },
    subtitle: {
      ...texts.title2,
      font: `400 38px/50px ${fonts.text}`,
      letterSpacing: '-1px',
    },
    lead: { ...texts.lead },
    premiumTopic: {
      subtitle: {
        color: '#bababa',
      },
      background: atoms.colors.layout,
    },
  };

  const topicTopline = {
    font: `bold 14px/1 ${fonts.nuance}`,
    color: color(atoms.colors.primary).alpha(0.4).string(),
    textTransform: 'uppercase',
    rubric: {
      color: atoms.colors.primary,
      padding: '4px 0 2px 0',
      border: 'none',
      borderBottom: `2px solid ${atoms.colors.active1}`,
      borderRadius: '0',
      textTransform: 'uppercase',
      hover: {
        color: atoms.colors.active1,
        borderBottom: '2px solid transparent',
        background: 'transparent',
      },
      premiumTopic: {
        color: atoms.colors.active1,
      },
    },
  };

  const expert = {
    name: {
      idle: {
        font: `700 14px/18px ${fonts.display}`,
        borderBottom: `2px solid ${atoms.colors.active1}`,
        margin: '0 0 5px 0',
      },
      hover: {
        color: atoms.colors.primary,
        font: `700 14px/18px ${fonts.display}`,
        borderBottom: '2px solid transparent',
        textTransform: 'uppercase',
      },
    },
    jobTitle: {
      color: atoms.colors.placeholder,
      font: `400 13px/20px ${fonts.text}`,
    },
    label: {
      background: atoms.colors.primary,
      font: `700 11px/1 ${fonts.text}`,
      textTransform: 'none',
      letterSpacing: '0.02em',
      borderRadius: '4px',
    },
  };

  const notes = {
    background: atoms.colors.content,
    headlineFontFamily: fonts.text,
    decoration: 'none',
    linkType: 'primary',
  };

  const paginatron = {
    radius: button.const.radius,
  };

  const story = {
    slide: {
      width: 140,
    },
  };

  const storyCard = {
    card: {
      borderRadius: '4px',
    },
    headline: {
      font: `bold 14px/17px ${fonts.display}`,
    },
    statusLine: {
      borderRadius: '4px',
    },
    premium: {
      TopIcon: PremiumStory,
      BottomIcon: PremiumStory,
    },
  };

  const storyWidgetsBaseCard = {
    headline: {
      font: `700 24px/30px ${fonts.display}`,
      textTransform: 'none',
    },
    lead: {
      font: `16px/20px ${fonts.text}`,
    },
    button: {
      minWidth: 100,
    },
  };

  const reactions = {
    tiny: {
      font: `600 12px/15px ${fonts.text}`,
    },

    small: {
      font: `500 14px/16px ${fonts.display}`,
      radius: '14px',
      padding: '4px 7px',
    },

    primary: {
      idle: {
        color: atoms.colors.active1,
        border: '1px solid transparent',
        background: 'transparent',
      },
      checked: {
        color: atoms.colors.active1,
        border: `1px solid ${atoms.colors.active1}`,
        background: 'transparent',
      },
      focus: {
        color: atoms.colors.active1,
        border: '1px solid transparent',
        background: 'transparent',
      },
      disabled: {
        color: atoms.colors.active1,
        border: '1px solid transparent',
        background: 'transparent',
      },
      checkedDisabled: {
        color: atoms.colors.active1,
        border: `1px solid ${atoms.colors.active1}`,
        background: 'transparent',
      },
    },
  };

  const reactionsEmojiBar = {
    const: {
      padding: '31px 29px',
      borderRadius: '5px',
      background: atoms.colors.active1,
      border: `1px solid ${atoms.colors.divider}`,
      boxShadow: 'none',
    },

    title: {
      font: `700 18px/22px ${fonts.display}`,
    },
  };

  const gameCompare = {
    showComments: true,
    tooltip: {
      theme: 'dark',
    },
    progressbar: {
      strokeColor: atoms.colors.darkGrey,
      background: atoms.colors.footer,
    },
    leaderBoard: {
      card: CompareCard,
      table: {
        background: '#212121',
      },
      count: {
        background: atoms.colors.darkGrey,
      },
      progressbar: {
        background: atoms.colors.darkGrey,
        activeBackground: '#4b4b4b',
      },
      header: {
        questionBackground: color('#c4c4c4').alpha(0.2).toString(),
        textTransform: 'uppercase',
        color: atoms.colors.grey,
      },
      modalWindow: {
        iconFill: '#777777',
      },
      draw: {
        background: atoms.colors.footer,
      },
    },
    indent: TopicContentIndent,
    compare: {
      borderRadius: 10,
      credits: {
        color: atoms.colors.grey,
      },
      caption: {
        background: atoms.colors.footer,
      },
      text: {
        background: atoms.colors.darkGrey,
      },
      draw: {
        backgroundColor: atoms.colors.darkGrey,
      },
    },
    share: {
      textTransform: 'uppercase',
      color: atoms.colors.grey,
      marginTop: '30px',
    },
  };

  const captionCredits = {
    maxWidth: '808px',
  };

  const login = {
    popup: {
      background: atoms.colors.layout,
      button: {
        font: `600 16px/19px ${fonts.text}`,
      },
      content: {
        font: `400 12px/18px ${fonts.text}`,
      },
    },
    modal: {
      content: {
        font: `400 16px/23px ${fonts.text}`,
      },
    },
  };

  const cookiesBanner = {
    button: {
      btnSize: 'extraSmall',
      btnType: 'primary',
      btnRadius: '5px',
      btnText: `600 16px/18px ${fonts.text}`,
    },
  };

  return deepmerge({
    fonts,
    icons,
    texts,
    controls: {
      shapka,
      scooter,
      topicFooter,
      topicTags,
      link,
      button,
      breadcrumbs,
      gallery,
      topicPages,
      input,
      bouesque,
      bullet,
      comments,
      topicContent,
      spammer,
      drum,
      vikontImage,
      vikontReadMore,
      vikontIncut,
      commentsPreview,
      smartTeaser,
      author,
      personal,
      fontSizeChangerButtons,
      topicHeaderTexts,
      topicTopline,
      expert,
      notes,
      mark,
      story,
      storyCard,
      storyWidgetsBaseCard,
      reactions,
      reactionsEmojiBar,
      paginatron,
      gameCompare,
      captionCredits,
      login,
      cookiesBanner,
    },
    ads: {
      sponsored: { ...texts.topicInfo },
      urlPreviewImage: {
        retina: require('site/pages/images/prime/macbookPreview-2x.png'),
        common: require('site/pages/images/prime/macbookPreview-1x.png'),
      },
    },
    layout,
    color: {
      adsColor: '#000',
    },
    pages: {
      error: errorPage,
      notFound: {
        ...errorPage,
        imgUrl: notFoundImg,
        title: {
          ...errorPage.title,
          name: '404',
        },
        subtitle: {
          ...errorPage.subtitle,
          name: 'Страница не найдена',
        },
      },
      sentryFallback: {
        ...errorPage,
        imgUrl: jsError,
        title: {
          ...errorPage.title,
          name: 'Что-то пошло не так',
        },
        subtitle: {
          ...errorPage.title,
          name: 'Мы уже занем об этом и скоро все исправим',
        },
        background: atoms.colors.layout,
        imgWidth: 800,
        card: null,
      },
    },
  }, atoms);
}
