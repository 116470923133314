import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { bebop as bebopApi } from 'core/api/definitions/bebop';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import AdWrapper from 'core/components/Ad/AdWrapper';
import { Mobile, Desktop } from 'core/components/breakpoint';
import { Block, Section } from 'core/components/Grid';
import H2 from 'core/components/H2';
import Link from 'core/components/Link';
import Bouesque from 'core/components/Bouesque';
import StickyPortal from 'core/components/StickyPortal';
import Button from 'core/components/Button';
import Page from 'core/components/Page';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import Popular from 'site/components/Popular';
import FooterAds from 'site/components/FooterAds';
import { PartnersVertical } from 'site/components/Partners';
import SelfPromo from 'core/components/SelfPromo';
import Selection, {
  relationships,
  SelectionCard,
  requiredPayloadImports as selectionIncludes,
  requiredPayloadFields as selectionFields,
} from 'site/components/Selection';
import PageLoader from 'core/components/Loader/PageLoader';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';

import {
  ListMain,
  ListPulse,
  List3,
  ListAdv,
  List5,
  List7,
  List8,
} from './lists';

import {
  SideIndent,
  InnerIndent,
  VerticalIndent,
  NegativeMobileSideIndent,
} from 'site/components/Indents';

import {
  SuperFooter,
  Vitrina1,
  Vitrina2,
  Vitrina3,
  Vitrina4,
  Parallax,
  Context,
  VerticalFirst,
  VerticalSecond,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
  Listing3,
  ListingSpec,
} from 'site/components/Ads/mobile';

import { pulseBaseUrl } from 'site/constants';

import TitleWithLink from './TitleWithLink';


function MainPage(props) {
  const {
    isDesktop,
    isMobile,
  } = props;
  const list3Cards = isMobile ? [Card1, Card2] : [Card1];
  const listAdvCards = isMobile ? [Card2, Card3] : [Card1];
  const list8Cards = isMobile ? [Card2] : [Card1];

  const results = useRequest({
    queries: [
      {
        queryKey: ['bebopApi', 'getTopics', {
          list: 'main',
          sort: 'list',
          limit: 5,
          include: filterRequiredParams([Card1], 'include'),
          fields: filterRequiredParams([Card1], 'fields'),
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      }, {
        queryKey: ['bebopApi', 'getTopics', {
          list: 'pulse',
          sort: 'list',
          limit: 8,
          include: filterRequiredParams([Card2, Card3], 'include'),
          fields: filterRequiredParams([Card2, Card3], 'fields'),
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      }, {
        queryKey: ['bebopApi', 'getTopics', {
          list: 'list-3',
          sort: 'list',
          limit: 4,
          include: filterRequiredParams(list3Cards, 'include'),
          fields: filterRequiredParams(list3Cards, 'fields'),
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      }, {
        queryKey: ['bebopApi', 'getTopics', {
          list: 'list-adv',
          sort: 'list',
          limit: 4,
          include: filterRequiredParams(listAdvCards, 'include'),
          fields: filterRequiredParams(listAdvCards, 'fields'),
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      }, {
        queryKey: ['bebopApi', 'getTopics', {
          sort: 'list',
          limit: 1,
          include: selectionIncludes.join(),
          fields: selectionFields.join(),
          list: 'commercial-1',
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      }, {
        queryKey: ['bebopApi', 'getTopics', {
          sort: 'list',
          limit: 1,
          include: selectionIncludes.join(),
          fields: selectionFields.join(),
          list: 'commercial-2',
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      }, {
        queryKey: ['bebopApi', 'getTopics', {
          list: 'list-5',
          sort: 'list',
          limit: 4,
          include: filterRequiredParams(listAdvCards, 'include'),
          fields: filterRequiredParams(listAdvCards, 'fields'),
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      }, {
        queryKey: ['bebopApi', 'getTopics', {
          list: 'list-7',
          sort: 'list',
          limit: 9,
          include: filterRequiredParams([Card2], 'include'),
          fields: filterRequiredParams([Card2], 'fields'),
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      }, {
        queryKey: ['bebopApi', 'getTopics', {
          list: 'list-8',
          sort: 'list',
          limit: 3,
          include: filterRequiredParams(list8Cards, 'include'),
          fields: filterRequiredParams(list8Cards, 'fields'),
        }],
        queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      },
    ],
  });

  const [
    main,
    pulse,
    list3,
    listAdv,
    commercial1,
    commercial2,
    list5,
    list7,
    list8,
  ] = results.map(({ data }) => denormalizeData(data));

  const commercial1Ids = relationships(commercial1?.[0]).read_more.topic_ids;
  const commercial2Ids = relationships(commercial2?.[0]).read_more.topic_ids;

  const commercialResults = useRequest({ queries: [{
    queryKey: ['bebopApi', 'getTopics', {
      ids: commercial1Ids,
      visibility: 'main_page',
      include: filterRequiredParams([SelectionCard], 'include'),
      fields: filterRequiredParams([SelectionCard], 'fields'),
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    enabled: !!commercial1Ids?.length,
    select: data => ({
      selection: commercial1?.[0],
      topics: denormalizeData(data),
    }),
  }, {
    queryKey: ['bebopApi', 'getTopics', {
      ids: commercial2Ids,
      visibility: 'main_page',
      include: filterRequiredParams([SelectionCard], 'include'),
      fields: filterRequiredParams([SelectionCard], 'fields'),
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    enabled: !!commercial2Ids?.length,
    select: data => ({
      selection: commercial2?.[0],
      topics: denormalizeData(data),
    }),
  }] });

  const resultsAreLoading = results.some(({ isLoading }) => isLoading);
  const commercialsAreLoading = commercialResults.some(({ isLoading }) => isLoading);

  if (resultsAreLoading || commercialsAreLoading) return <PageLoader />;

  return (
    <Page>
      <PageIndent>
        <ColumnLayout
          rightColumn={(
            <>
              <Bouesque />
              <VerticalIndent indent={16} />
              <StickyPortal listenStickyRemoveEvent>
                <VerticalFirst />
                <VerticalIndent indent={16} />
                <SelfPromo />
                <VerticalIndent indent={16} />
              </StickyPortal>
              <StickyPortal>
                <PartnersVertical />
                <VerticalIndent indent={38} />
                <AdWrapper bottom={30}>
                  <VerticalSecond />
                </AdWrapper>
              </StickyPortal>
            </>
          )}
        >
          <ListMain content={main} />

          <Desktop>
            <SuperFooter />
            <VerticalIndent indent={20} />
          </Desktop>

          <TitleWithLink
            title='Пульс'
            to={pulseBaseUrl}
            headerComponent={H2}
          />
          <VerticalIndent indent={isDesktop ? 15 : 12} />

          <ListPulse content={pulse} />

          <Desktop>
            <Link to={pulseBaseUrl}>
              <Button minWidth='100%'>Все новости</Button>
            </Link>
          </Desktop>

          <Mobile>
            <NegativeMobileSideIndent>
              <Listing1 />
            </NegativeMobileSideIndent>
            <VerticalIndent indent={10} />
          </Mobile>

          <VerticalIndent indent={isDesktop ? 30 : 15} />

          <List3 content={list3} />

        </ColumnLayout>
      </PageIndent>

      <Desktop>
        <Section>
          <SideIndent />
          <Block>
            <Parallax />
          </Block>
          <SideIndent />
        </Section>
        <VerticalIndent indent={30} />
      </Desktop>

      <PageIndent>
        <ColumnLayout
          rightColumn={(
            <>
              <Popular />
              <VerticalIndent indent={16} />
            </>
          )}
        >
          <NegativeMobileSideIndent>
            <Mobile>
              <ListingSpec />
              <VerticalIndent indent={10} />
            </Mobile>

            <Selection content={commercialResults[0].data} />
          </NegativeMobileSideIndent>

          <Desktop>
            <VerticalIndent indent={20} />
            <Section>
              <InnerIndent />
              <Block>
                <Vitrina1 />
              </Block>
              <InnerIndent />
              <Block>
                <Vitrina2 />
              </Block>
              <InnerIndent />
              <Block>
                <Vitrina3 />
              </Block>
              <InnerIndent />
              <Block>
                <Vitrina4 />
              </Block>
              <InnerIndent />
            </Section>
          </Desktop>

          <VerticalIndent indent={isDesktop ? 30 : 20} />

          <ListAdv content={listAdv} />

          <Desktop>
            <Context />
            <VerticalIndent indent={20} />
          </Desktop>
          <Mobile>
            <NegativeMobileSideIndent>
              <Listing2 />
            </NegativeMobileSideIndent>
            <VerticalIndent indent={8} />
          </Mobile>

          <List5 content={list5} />

          <Mobile>
            <TitleWithLink
              title='Тесты и опросы'
              to='#'
              headerComponent={H2}
            />
          </Mobile>

          <List7 content={list7} />

          <VerticalIndent indent={isDesktop ? 30 : 10} />

          <NegativeMobileSideIndent>
            <Mobile>
              <Listing3 />
              <VerticalIndent indent={10} />
            </Mobile>

            <Selection content={commercialResults[1].data} />
          </NegativeMobileSideIndent>

          <VerticalIndent indent={isDesktop ? 30 : 20} />

          <List8 content={list8} />

          <AdWrapper bottom={isDesktop ? 30 : 10}>
            <FooterAds contextCount={2} />
          </AdWrapper>
        </ColumnLayout>
      </PageIndent>
    </Page>
  );
}

MainPage.propTypes = {
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(MainPage);
